import request from '@/utils/request'
import { defineAsyncComponent } from 'vue'
import DOMPurify from 'dompurify';
export default {

  formatThousandth(row, column, cellValue, index) {
    if (cellValue == null || cellValue === '') {
      return cellValue;
    }
    return cellValue.toLocaleString();
  },

  handlePreview(fileStream, fileType) {
    let obj = {
      url: '',
      componentName: null
    }
    if (fileType === 'jpg' || fileType === 'png') {
      const blob = new Blob([fileStream], { type: 'image/jpeg' });
      obj.url = URL.createObjectURL(blob);
    } else if (fileType === 'pdf' || fileType === 'ppt' || fileType === 'pptx') {
      obj.componentName = defineAsyncComponent(() => import('@vue-office/pdf'));
      const blob = new Blob([fileStream]);
      obj.url = URL.createObjectURL(blob);
    } else if (fileType === 'docx' || fileType === 'doc') {
      obj.componentName = defineAsyncComponent(() => import('@vue-office/docx'));
      const blob = new Blob([fileStream]);
      obj.url = URL.createObjectURL(blob);
    } else if (fileType === 'xlsx' || fileType === 'xls') {
      obj.componentName = defineAsyncComponent(() => import("@vue-office/excel"));
      const blob = new Blob([fileStream]);
      obj.url = URL.createObjectURL(blob);
    } else if (fileType === 'pptx') {
      const blob = new Blob([fileStream], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
      obj.url = URL.createObjectURL(blob);
    } else if (fileType === 'mp4' || fileType === 'avi') {
      const blob = new Blob([fileStream], { type: 'video/mp4' });
      obj.url = URL.createObjectURL(blob);
    }
    return obj;
  },

  //设置表格状态值为128或7时候的行颜色
  //row:当前行, rowIndex：当前行索引, statusFlag：当前行状态值, strStatus：需要设置的状态
  tableRowClassName(row, rowIndex, statusFlag, strStatus) {
    //  console.log('aaaa', statusFlag, strStatus.indexOf(',128,'))
    //如果需要128，7，都显示颜色，则strStatus=',128,7,'这样的模式
    // if (row.row.statusFlag === 128 && strStatus.indexOf(',128,') != -1) {
    if (statusFlag === 128 && strStatus.indexOf(',128,') != -1) {
      return 'warning-row'
    }
    // else if (row.row.statusFlag === 7 && strStatus.indexOf(',7,') != -1) {
    else if (statusFlag === 7 && strStatus.indexOf(',7,') != -1) {
      return 'success-row'
    }
    return ''
  },

  //整数转成大写字母
  numberToLetter(inNumber) {
    // 64=A.charCodeAt(),26个字符，m=0表示26=Z
    let resultstr = '';
    while (inNumber > 0) {
      let m = inNumber % 26;//取余值
      m = m === 0 ? (m = 26) : m;
      resultstr = String.fromCharCode(64 + parseInt(m)) + resultstr;
      inNumber = (inNumber - m) / 26;
    }
    return resultstr;
  },


  // 将阿拉伯数字转成中文的数字
  arabicToChinese(num) {
    const units = ['', '十', '百', '千', '万', '亿'];
    const chnNumChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];

    let result = '';
    let digitCount = 0;

    num = num.toString();

    for (let i = num.length - 1; i >= 0; i--) {
      const digit = parseInt(num[i]);
      const unit = units[digitCount];

      if (digit !== 0) {
        result = chnNumChar[digit] + unit + result;
      } else if (result.length > 0 && result[0] !== '零') {
        result = '零' + result;
      }

      digitCount++;
      if (digitCount === 4) {
        digitCount = 0;
      }
    }

    return result;
  },

  // 根据list的值转换成显示的内容，
  // list是所有的选项，
  // valueKey是list中的对象中value对应的key,
  // labelKey是list中需要显示的内容对应的key
  valueToLabel(list, valueKey, labelKey, value) {
    if (list == null || list.length == 0) {
      return '';
    }
    for (let i = 0; i < list.length; i++) {
      let obj = list[i];
      if (obj[valueKey] != null && obj[valueKey] == value) {
        let label = obj[labelKey];
        if (label == null) {
          label = '';
        }
        return label;
      }
    }
    return value;
  },

  /*table初始化回显数据 事件要放在open事件 例如 @open="toggleTableSelection"*/
  /*应用例子
    childQuestionSelection()
    {
       this.$nextTick(() => {
           this.$globalUtils.toggleTableSelection(this.childQuestionList,this.questionSelect,this.$refs.nextQuestionTable,true);
       });

    },
  */
  //selectData要传进来回显的数据
  //tableData表格数据：data=tableData
  //thisrefstableName要回显的表格，带前面的this.$refs.，例如this.$refs.nextQuestionTable
  //toggleBoolean表示勾选是否开或关。ture表示开，false表示关,undefined表示开关互换
  toggleTableSelection(selectData, tableData, thisrefstableName, toggleBoolean) {
    let ids = selectData.map((item) => item.id);
    let selectList = tableData.filter((item) => { return ids.includes(item.id) }) //转成列表的数据
    // this.$nextTick(() => {
    if (selectList) {
      for (let i = 0; i < selectList.length; i++) {
        thisrefstableName.toggleRowSelection(selectList[i], toggleBoolean);//设置行选 中 例this.$refs.nextQuestionTable
      }
    } else {
      thisrefstableName.clearSelection();//没有就清空行选中
    }
    // });
  },

  // 清除过滤富文本中的xss攻击
  purifyHtml(html) {
    if (html == null) {
      return '';
    }

    return DOMPurify.sanitize(html);   
  },


}