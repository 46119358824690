import request from '@/utils/request'
// import { fi } from 'element-plus/es/locale'

export default  {

    //分页查询
    getCompanyPage(condition) {
        return request({
            method: 'POST',
            url: '/buiBase/getCompanyPage',
            data: condition
        })
    },

    getParentCompany(type) {
        return request({
            method:'GET',
            url:'buiBase/getParentCompany',
            params: {
                type: type
            }
        })
    },

    getAllCustomer(){
        return request({
            method:'GET',
            url:'buiBase/getAllCustomer',
        })
    },

    
    getCompanyPage(data) {
        return request({
            url: '/buiBase/getCompanyPage',
            method: 'POST',
            data: data,
        })
    },

    getCompanyList(type) {
        return request({
            url: '/buiBase/getAllCompany',
            params: {
                type: type,
            },
            method: 'get',
        })
    },
    
    getMsg(id, type) {
        return request({
            method: 'get',
            url: '/buiBase/getCompanyMsg',
            params: {
                id: id,
                type: type,
            },
        })
    },
    getSettlement() {
        return request({
            method: 'get',
            url: '/buiBase/getSettlement',
        })
    },
    getCompanyByName(name, type) {
        return request({
            method: 'get',
            url: '/buiBase/getCompanyByName',
            params: {
                name: name,
                type: type,
            },
        })
    },
    registerCompany(data, submitApproval) {
        return request({
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'post',
            url: '/buiBase/registerCompany',
            data: data,
            params: {
                submitApproval: submitApproval,
            }
        })
    },
    editCompany(data, submitApproval) {
        return request({
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'post',
            url: '/buiBase/editCompany',
            data: data,
            params: {
                submitApproval: submitApproval,
            }
        })
    },
    delCompany(id, type) {
        return request({
            method: 'get',
            url: '/buiBase/delCompany',
            params: {
                id: id,
                type: type,
            },
        })
    },

    addAddr(data) {
        return request({
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'post',
            url: '/buiBase/addAddress',
            data: data
        })
    },
    editAddr(data) {
        return request({
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'post',
            url: '/api/editAddress',
            data: data
        })
    },
    delAddr(id) {
        return request({
            url: '/buiBase/delAddress/' + id,
            method: 'get',
        })
    },

    addStaff(data) {
        return request({
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'post',
            url: '/buiBase/addStaff',
            data: data
        })
    },

    editStaff(data) {
        return request({
            url: '/buiBase/editStaff',
            method: 'post',
            data: data
          })
    },

    delStaff(id) {
        return request({
            method: 'get',
            url: '/buiBase/delStaff/' + id,
            data: id
        })
    },

    getGroupByCompanyType(type){
        return request({
            method: 'get',
            url: 'sysbase/group/getGroupByCompanyType',
            params: {
                companyType: type
            }
        })
    },

    getAllGroup(){
        return request({
            method: 'get',
            url: 'sysbase/group/getAllGroup',
        })
    },

    getAllBrandDef(){
        return request({
            method: 'get',
            url: 'purchase/goods/getAllBrandDef'
        })
    },

    addCompanyGroup(ids,companyId,companyType){
        return request({
            method: 'post',
            url: 'sysbase/companyGroup/addCompanyGroup',
            data: ids,
            params: {
                companyId: companyId,
                companyType: companyType
            }
        })
    },

    getCompanyByCompanyId(companyId,companyType){
        return request({
            method: 'get',
            url: 'sysbase/companyGroup/getCompanyGroup',
            params: {
                companyId: companyId,
                companyType: companyType
            }
        })
    },

    //查找有权限的companyId
    getCompanyIdMySelf(){
        return request({
            method: 'get',
            url: 'sysbase/companyGroup/getCompanyId'
        })
    },

    getCompanyByCompanyIds(type,companyIds){
        return request({
            method: 'post',
            url: 'buiBase/getCompanyByCompanyIds',
            data: companyIds,
            params: {
                type: type
            }
        })
    },
}
