import request from '@/utils/request'

export default {
    getAllQuestion() {
        return request({
            url: '/questionnaire/getQuestionList',
            method: 'get',
        })
    },
    getAllQuestionPage(condition) {
        return request({
            url: '/questionnaire/getQuestionListPage',
            method: 'post',
            data:condition
        })
    },
    deleteQuestionnaireFile(filePath) {
        return request({
            url: '/questionnaire/deleteQuestionnaireFile',
            method: 'get',
            params: {
                answers: filePath
            }
        })
    },
    questionnaireDeleteFTPFile(fileName,filePath) {
        return request({
            url: '/questionnaire/questionnaireDeleteFTPFile',
            method: 'get',
            params: {
                fileName:fileName,
                filePath:filePath
            }
        })
    },

    questionnaireUploadFile(data) {
        return request({
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'post',
            url: 'questionnaire/questionnaireUploadFile',
            data: data
        })
    },
    // searchQuestionnaireQuestionAnswers(questionnaireId,questionId,searchAnswerItem){
    //     return request({
    //         url: '/questionnaire/searchQuestionnaireQuestionAnswers',
    //         method: 'get',
    //         params: {
    //             questionnaireId:questionnaireId,
    //             questionId:questionId,
    //             searchAnswer:searchAnswerItem,
    //         }
    //     })
    // },
    getQuestionnaireQuestionAnswers(questionnaireId,questionId){
        return request({
            url: '/questionnaire/getQuestionnaireQuestionAnswers',
            method: 'get',
            params: {
                questionnaireId:questionnaireId,
                questionId:questionId
            }
        })
    },
    getQuestionnaireStatistics(questionnaireId){
        return request({
            url: '/questionnaire/getQuestionnaireStatistics',
            method: 'get',
            params: {
                questionnaireId:questionnaireId,
            }
        })
    },
    getQuestionAnswersCommentByQuestionSetId(questionSetIdList) {
        return request({
            url: '/questionnaire/getCommentByQuestionSetId',
            method: 'get',
            params: {
                questionSetIdList:questionSetIdList
            }
        })
    },
    getQuestionByBusinessAndName(businessType,questionName,questionCode) {
        return request({
            url: '/questionnaire/getQuestionByBusinessAndName',
            method: 'get',
            params: {
                businessId:businessType,
                questionName:questionName,
                questionCode:questionCode
            }
        })
    },
    getQuestionSetRelateOption(questionnaireId) {
        return request({
            url: '/questionnaire/getQuestionSetRelateOption',
            method: 'get',
            params: {
                questionnaireId:questionnaireId,
            }
        })
    },
    getQuestionnaireByTwo(questionnaireName,questionnaireType) {
        return request({
            url: '/questionnaire/getQuestionnaireByTwo',
            method: 'get',
            params: {
                questionnaireName:questionnaireName,
                type:questionnaireType,
            }
        })
    },
    getQuestionAndAnswer(questionId) {
        return request({
            url: '/questionnaire/getQuestionAndAnswer',
            method: 'get',
            params: {
                questionId:questionId,
            }
        })
    },
    getQuestionnaireStationByQuestionnaireId(questionnaireId) {
        return request({
            url: '/questionnaire/getQuestionnaireStationByQuestionnaireId',
            method: 'get',
            params: {
                questionnaireId: questionnaireId,
            },
        })
    },
    addQuestionnaireStation(questionnaireId,stationList) {
        return request({
            url: '/questionnaire/addQuestionnaireStation',
            method: 'post',
            params: {
                questionnaireId: questionnaireId,
            },
            data:stationList
        })
    },
    getStation() {
        return request({
            url: '/questionnaire/getStation',
            method: 'get',
        })
    },
    changeQuestionSetQuestionnaire(questionnaireId,questionSetList) {
        return request({
            url: '/questionnaire/changeQuestionSetQuestionnaire',
            method: 'post',
            params: {
                questionnaireId: questionnaireId,
            },
            data:questionSetList
        })
    },
    getQuestionnaireById(questionnaireId) {
        return request({
            url: '/questionnaire/getQuestionnaireById',
            method: 'get',
            params: {
                questionnaireId: questionnaireId,
            }
        })
    },
    delQuestionSetQuestionsForPatch(questionnaireId, questionIdList,questionSetId) {
        return request({
            url: '/questionnaire/delQuestionSetQuestionsForPatch',
            method: 'post',
            params: {
                questionnaireId: questionnaireId,
                questionIdList: questionIdList,
                questionSetId:questionSetId,
            },
        })
    },

    getQuestionListByListId(QuestionIdList){
        return request({
            url: '/questionnaire/getQuestionListByListId',
            method: 'get',
            params:{
                QuestionIdList:QuestionIdList
            }
        })
    },

    getQuestionSetByQuestionnaireId(questionnaireId){
        return request({
            url: '/questionnaire/getQuestionSetByQuestionnaireId',
            method: 'get',
            params:{
                questionnaireId:questionnaireId
            }
        })
    },
    getAllQuestionSetAndAnswer() {
        return request({
            url: '/questionnaire/getAllQuestionSetAndAnswer',
            method: 'get',
        })
    },
    getAllQuestionSet() {
        return request({
            url: '/questionnaire/getAllQuestionSet',
            method: 'get',
        })
    },
    getAllQuestionSetPage(condition) {
        return request({
            url: '/questionnaire/getAllQuestionSetPage',
            method: 'post',
            data:condition
        })
    },
    getQuestionSetById(questionSetId) {
        return request({
            url:'/questionnaire/getQuestionSetAnswers',
            method:'get',
            params:{
              questionSetId:questionSetId  
            },
        })
    },
    delQuestionSetById(questionSetId){
        return request({
            url:'/questionnaire/delQuestionSetById',
            method:'post',
            params:{
                questionSetId:questionSetId  
              },
        })
    },
    addQuestionSetInQuestionnaire(questionnaireId,questionSetId){
        return request({
            url: '/questionnaire/addQuestionSetInQuestionnaire',
            method: 'post',
            params: {
                questionnaireId: questionnaireId,
            },
            data: questionSetId,
        })
    },
    saveQuestionCommentList(questionCommentListDTO) {
        return request({
            url: '/questionnaire/saveQuestionCommentList',
            method: 'post',
            data:questionCommentListDTO
        })
    },

    changeQuestionCommentList(questionCommentListDTO) {
        return request({
            url: '/questionnaire/changeQuestionCommentList',
            method: 'post',
            data:questionCommentListDTO
        })
    },

    getChoiceQuestions(){
        return request({
            url: '/questionnaire/getChoiceQuestions',
            method: 'get',
        })
    },
    getCustomer(){
        return request({
            url: '/questionnaire/getAllCompaniesClient',
            method: 'get',
            params: {
                type: 102,
            },
        })
    },
    getAnswerName(answerId){
        return request({
            url: '/questionnaire/getAnswerName',
            method: 'get',
            params: {
                answerId: answerId,
            },
        })
    },
    getAllSendQuestionnaireName(){
        return request({
            url: '/questionnaire/getQuestionnaireNameById',
            method: 'get',
        })
    },
    getMyQuestionnaire(customerId,staffId){
        return request({
            url: '/questionnaire/getMyQuestionnaire',
            method: 'get',
            params: {
                customerId:customerId,
                staffId:staffId
            }
        })
    },
    getQuestionnaireBy(questionnaireName, company) {
        return request({
            url: '/questionnaire/getQuestionnaireBy',
            method: 'post',
            params: {
                questionnaireName: questionnaireName,
                company: company,
            },
        })
    },
    getMyQuestionnaireBy(questionnaireName, companyId) {
        return request({
            url: '/questionnaire/getMyQuestionnaireBy',
            method: 'post',
            params: {
                questionnaireName: questionnaireName,
                companyId: companyId,
            },
        })
    },
    delQuestionCustomer(questionnaireId, customerId) {
        return request({
            
            url: '/questionnaire/delQuestionnaireCustomer',
            method: 'post',
            params: {
                questionnaireId: questionnaireId,
                customerId: customerId,
            },
        })
    },
    getBusiness() {
        return request({
            url: '/questionnaire/getBusiness',
            method: 'get',
        })
    },
    addQuestion(data) {
        return request({
            url: '/questionnaire/addQuestion',
            method: 'post',
            data: data,
        })
    },
    addQuestionnaire(data) {
        return request({
            url: '/questionnaire/addQuestionnaire',
            method: 'post',
            data: data,
        })
    },
    getAllQuestionnaire() {
        return request({
            url: '/questionnaire/getQuestionnaireList',
            method: 'get',
        })
    },

    getAllQuestionnairePage(condition) {
        return request({
            url: '/questionnaire/getQuestionnaireListPage',
            method: 'post',
            data:condition
        })
    },
    delQuestionnaire(questionnaireId) {
        return request({
            url: '/questionnaire/delQuestionnaire/' + questionnaireId,
            method: 'get',
        })
    },
    delBusiness(id) {
        return request({
            url: '/questionnaire/delBusiness/' + id,
            method: 'get',
        })
    },
    changeBusiness(id, name, parentId,kindCode,businessGroup) {
        return request({
            url: '/questionnaire/changeBusiness',
            method: 'post',
            data: {
                id: id,
                name: name,
                parentId: parentId,
                kindCode: kindCode,
                businessGroup: businessGroup
            },
        })
    },
    addBusiness(name, parentId,kindCode,businessList) {
        return request({
            url: '/questionnaire/addBusiness',
            method: 'post',
            data: {
                name: name,
                parentId: parentId,
                kindCode : kindCode,
                businessGroup: businessList
            },
        })
    },
    getQuestion(questionId) {
        return request({
            url: '/questionnaire/getQuestion/' + questionId,
            method: 'get',
        })
    },
    delQuestionAnswer(answerId) {
        return request({
            url: '/questionnaire/delQuestionAnswer/' + answerId,
            method: 'get',
        })
    },
    changeQuestionAnswer(answerId, newAnswer, nextQuestion,relateGoodsList,newBlankType,comment) { 
        return request({
            url: '/questionnaire/changeQuestionAnswer',
            method: 'post',
            params: {
                answerId: answerId,
                content: newAnswer,
                jumpId: nextQuestion,
                relateGoodsList:relateGoodsList,
                blankType:newBlankType,
                comment:comment,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        })

    },
    addQuestionAnswer(content, nextQuestion, questionId,relateGoodsList,newBlankType,comment, sortOrder) {
        return request({
            url: '/questionnaire/addQuestionAnswer',
            method: 'post',
            params: {
                questionId: questionId,
                content: content,
                jumpId: nextQuestion,
                relateGoodsList: relateGoodsList,
                blankType:newBlankType,
                comment:comment,
                sortOrder: sortOrder
            },
        })
    },
    changeQuestion(data) {
        return request({
            url: '/questionnaire/changeQuestion',
            method: 'post',
            data: data,
        })
    },
    getQuestionnaire(id) {
        return request({
            url: '/questionnaire/getQuestionnaire/' + id,
            method: 'get',
        })
    },
    changeQuestionnaire(data) {
        console.log(data)
        return request({
            url: '/questionnaire/changeQuestionnaire',
            method: 'post',
            data: data,
        })
    },
    delQuestionnaireQues(questionnaireId, questionId) {
        return request({
            url: '/questionnaire/delQuestionnaireQues',
            method: 'post',
            params: {
                questionnaireId: questionnaireId,
                questionId: questionId,
            },
        })
    },
    addQuestionnaireQues(questionnaireId, questionList) {
        return request({
            url: '/questionnaire/addQuestionnaireQues',
            method: 'post',
            params: {
                questionnaireId: questionnaireId,
            },
            data: questionList,
        })
    },
    delQuestion(id) {
        return request({
            url: '/questionnaire/delQuestion/' + id,
            method: 'get',
        })
    },
    getQuestionnaireQuestion(questionnaireId,staffId,customerId,stationId,isCheck) {
        // console.log("customerID",customerId)
        return request({
            // url: '/questionnaire/getQuestionnaireQuestion/' + questionnaireId+'/'+customerId,
            url: '/questionnaire/getQuestionnaireQuestion',
            method: 'get',
            params: {
                questionnaireId:questionnaireId,
                customerId:customerId,
                stationId:stationId,
                staffId:staffId,
                isCheck:isCheck,
            }
        })
    },
    getQuestionnaireQuestionPublish(questionnaireId,staffId,customerId,stationId,isCheck) {
        // console.log("customerID",customerId)
        return request({
            // url: '/questionnaire/getQuestionnaireQuestion/' + questionnaireId+'/'+customerId,
            url: '/questionnaire/getQuestionnaireQuestionPublish',
            method: 'get',
            params: {
                questionnaireId:questionnaireId,
                customerId:customerId,
                stationId:stationId,
                staffId:staffId,
                isCheck:isCheck,
            }
        })
    },
    verifyQuestion(questionId, number) {
        return request({
            url: '/questionnaire/verifyQuestion',
            method: 'get',
            params: {
                id: questionId,
                type: number,
            },
        })
    },
    verifyQuestionnaire(id, number) {
        return request({
            url: '/questionnaire/verifyQuestionnaire',
            method: 'post',
            data: {
                id: id,
                type: number,
            },
        })
    },
    distributeQuestionnaire(id, customers) {
        return request({
            url: '/questionnaire/distributeQuestionnaireClient',
            method: 'post',
            params: {
                companyId: id,
            },
            data: customers
        })
    },
    submitAnswer(data, questionnaireId) {
        return request({
            url: '/questionnaire/answerQuestion',
            method: 'post',
            data: {
                answer: data,
                id: questionnaireId,
            },
        })
    },
    saveAnswer(questionAnswerList) {
        return request({
            url: '/questionnaire/saveAnswer',
            method: 'post',
            data:questionAnswerList
        })
    },
    saveCustomerAnswer(questionAnswerList) {
        return request({
            url: '/questionnaire/saveCustomerAnswer',
            method: 'post',
            data:questionAnswerList
        })
    },
    
    uploadFile(data) {
        return request({
            method: 'post',
            url: '/ftp/uploadFile',
            data: data,
            headers: {'Content-Type': 'multipart/form-data'},
        })
    },
    saveFileName(questionId, data) {
        return request({
            method: 'post',
            url: '/questionnaire/saveFileName',
            data: {
                questionId: questionId,
                name: data,
            },
        })
    },
    getFileImg(remark) {
        return request({
            method: 'get',
            url: '/questionnaire/showFileImg/' + remark,
            params: {
                fileName: remark,
                filePath: 'ky_env_img',
            },
            responseType: 'blob',
            headers: {
                Accept: 'application/octet-stream',
            }
        })
    },
    searchByCustomerName(customerName) {
        return request({
            method: 'get',
            url: '/questionnaire/getCompanyByNameClient',
            params: {
                name: customerName,
                type: 102,
            },
        })
    },
    getQuestionByName(questionName) {
        return request({
            url: '/questionnaire/getQuestionByName/',
            method: 'get',
            params: {
                name: questionName,
            },
        })
    },
    getCustomerQuestionnaire(id) {
        return request({
            url: '/questionnaire/getCustomerQuestionnaire/' + id,
            method: 'get',
        })
    },
    getCustomerQuestionAnswer(questionnaireId , customerId){
        return request({
            url: '/questionnaire/getCustomerQuestionAnswer',
            method: 'get',
            params:{
                questionnaireId: questionnaireId,
                customerId: customerId,
            },
        })
    },
    getAllQuestionnaireCustomer(){
        return request({
            url: '/questionnaire/getAllQuestionnaireCustomer',
            method: 'get',
        })
    },
    getAllQuestionnaireCustomerPage(condition){
        return request({
            url: '/questionnaire/getAllQuestionnaireCustomerPage',
            method: 'post',
            data: condition
        })
    },
    createOrder(customerId,questionnaireId){
        return request({
            url: '/questionnaire/createOrder',
            method: 'post',
            params: {
                customerId: customerId,
                questionnaireId: questionnaireId
            },
        })
    },

    updateQuestionAnswer(answerList) {
        return request({
            url: '/questionnaire/updateQuestionAnswer',
            method: 'post',
            data: answerList
        })
    },

    getGoodsListByGoodsIdString(goodsIdList) {
        return request({
            url: '/buiBase/goods/getGoodsListByGoodsIdString',
            method: 'get',
            params: {
                goodsIdList: goodsIdList               
            },
        })
    },
    
}
