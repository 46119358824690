<template>
  <div class="staff-list" style="padding: 5px">
    <div v-if="conditionList">
      <ky-list-condition-one-add ref="search" :condition-list="conditionList" @choose="choose"
        @search="search" :col-count="2" :col-span="12">
      </ky-list-condition-one-add>
    </div>

    <ky-table :data="tableData" row-key="staffId" @selection-change="handleSelectionChange" ref="tableRef" height="65vh">
      <template #action>
        <div style="padding: 5px; display: flex">
          <div class="ml5" v-if="addBtnVisible">
            <el-button @click="$router.push('/acl/staff/add-staff')">增加员工</el-button>
          </div>
          <el-radio-group style="margin-left: 750px" @change="changeCompanysList" v-model="isKindType">
            <el-radio :label="1">默认</el-radio>
            <el-radio :label="2">按部门展示</el-radio>
          </el-radio-group>
          <div class="ml5" v-if="confirmChooseBtnVisible">
            <el-button @click="confirmChooseClick">确认选择</el-button>
          </div>
        </div>
      </template>
      <!-- <el-table-column fixed  type="selection" /> -->
      <el-table-column fixed v-if="isKindType == 2" prop="companyDeptName" label="部门" width="auto" />
      <el-table-column fixed prop="nickName" label="用户昵称" />
      <el-table-column v-if="isKindType == 1" prop="companyDeptName" label="部门" />
      <!-- <el-table-column fixed  prop="mobilePhone" label="手机号" /> -->
      <el-table-column prop="username" label="用户名" />   
      <el-table-column label="登录状态" width="65">
        <template #default="scope">
          <el-switch
            v-if="!scope.row.dept"
            v-model="scope.row.loginStatus"
            :active-value="1"
            :inactive-value="2"
            @click="changeLoginStatus(scope.row)"/>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <!-- :disabled="!checkPermission(['permission.role.saveOrRemoveRole'])" -->
          <el-button :disabled="!checkPermission(['permission.user.saveUser'])" @click="addLoginUser(scope.row)" type="primary" v-if="!scope.row.dept" plain>创建登录用户</el-button>
          <el-button type="primary" v-if="!scope.row.dept" plain @click="getStaffMsg(scope.row)">查看</el-button>
          <el-button type="primary" v-if="!scope.row.dept" plain @click="edit(scope.row)">编辑</el-button>
          <el-button type="danger" v-if="!scope.row.dept" plain @click="deleteById(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </ky-table>
    <el-pagination
      v-model:currentPage="page.pageNum"
      v-model:page-size="page.pageSize"
      :page-sizes="[20, 50, 100, 200]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange">
    </el-pagination>

    <el-dialog v-model="detailDialogVis" :title="'员工详情'">
      <div style="padding: 10px">
        <el-descriptions :column="3" border>
          <el-descriptions-item label="用户名">{{companyDept.username}}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{companyDept.mobilePhone}}</el-descriptions-item>
          <el-descriptions-item label="昵称">{{companyDept.nickName}}</el-descriptions-item>
          <el-descriptions-item label="姓名">{{companyDept.staffName}}</el-descriptions-item>
          <el-descriptions-item label="部门">{{companyDept.companyDeptName}}</el-descriptions-item>
          <el-descriptions-item label="性别">
            <span v-if="companyDept.gender === 1">男</span>
            <span v-else-if="companyDept.gender === 2">女</span>
          </el-descriptions-item>
          <el-descriptions-item label="国籍">
            {{allNationality.find((item) => item.nationality === 1).nationalityName}}
          </el-descriptions-item>
          <el-descriptions-item label="出生日期">{{companyDept.birthDate}}</el-descriptions-item>
          <el-descriptions-item label="身份证号码">{{companyDept.idCard}}</el-descriptions-item>
          <el-descriptions-item label="入职日期">{{companyDept.employeedDate}}</el-descriptions-item>
          <el-descriptions-item label="离职日期">{{companyDept.retiredDate}}</el-descriptions-item>
          <el-descriptions-item label="紧急联系人">{{companyDept.firstContactsPerson}}</el-descriptions-item>
          <el-descriptions-item label="第二联系人">{{companyDept.secContactsPerson}}</el-descriptions-item>
          <el-descriptions-item label="紧急联系人电话">{{companyDept.firstContactsPhone}}</el-descriptions-item>
          <el-descriptions-item label="第二联系人电话">{{companyDept.secContactsPhone}}</el-descriptions-item>
          <el-descriptions-item label="头像路径文件名">{{companyDept.imagePath}}</el-descriptions-item>
          <el-descriptions-item label="证书路径文件名">{{companyDept.certificationPath}}</el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>

    <!--  添加登陆账户  -->
    <el-dialog :before-close="refreshData" :title="dialogTitle" v-model="dialogFormVisible" width="500px">
      <el-form ref="user" :model="user" :rules="userRules">
        <el-form-item label="用户名" prop="username" label-width="100px">
          <el-input style="width: 300px; margin-bottom: 10px;" v-model="user.username" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item label="密&nbsp;&nbsp;&nbsp;码" prop="pwd" label-width="100px">
          <el-input style="width: 300px;" type="password" v-model="user.pwd" autocomplete="on"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveUser">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import userReq from "@/api/acl/user";
import companyDeptReq from "@/api/acl/company_dept";
import moment from "moment";
import Md5_utils from "@/utils/md5_utils";
import { ref } from 'vue'

const isKindType = ref(1)
export default {
  name: "ky-staff-list",
  emits: ["confirmChoose"],
  props: {
    editBtnVisible: {
      type: Boolean,
      default: true,
    },
    detailBtnVisible: {
      type: Boolean,
      default: true,
    },
    deleteBtnVisible: {
      type: Boolean,
      default: true,
    },
    addBtnVisible: {
      type: Boolean,
      default: true,
    },
    confirmChooseBtnVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isKindType: 1,
      dialogFormVisible: false,
      detailDialogVis: false,
      selection: [],
      conditionList: [],
      tableData: [],
      userList: [],
      childrenMap: {},
      page: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      user: {
        staffId: "",
        mobilePhone: "",
        username: "",
        pwd: "",
        nickName: "",
      },
      userRules: {
        mobilePhone: [
          { required: true, trigger: "blur", message: "手机号必须输入" },
        ],
        pwd: [{ required: true, trigger: "blur", message: "密码必须输入" }],
        nickName: [
          { required: true, trigger: "blur", message: "用户昵称必须输入" },
        ],
        username: [
          { required: true, trigger: "blur", message: "用户名必须输入" },
        ],
      },
      companyDept: {},
      allNationality: [],
      allCompanyDeptA: [],
    };
  },
  async created() {
    const res1 = await companyDeptReq.selectAllCompanyDept();
    this.allCompanyDeptA = res1.data || [];
    // console.log(this.allCompanyDeptA)
    const res2 = await companyDeptReq.getAllNationality();
    this.allNationality = res2.data || [];
    // let condition = [];
    // this.getTree();
    this.page.condition = [];
    this.search();
    this.conditionList = [
      // {
      //   label: '用户名',
      //   propertyType: 'string',
      //   propertyName: 'username',
      //   supportMatchTypeList: [
      //     {
      //       label: '像',
      //       value: 'like'
      //     },
      //   ]
      // },
      {
        label: "手机号",
        propertyType: "number",
        propertyName: "mobilePhone",
        supportMatchTypeList: [
          {
            label: "像",
            value: "like",
          },
        ],
      },
      {
        label: "部门名称",
        propertyType: "string",
        propertyName: "companyDeptName",
        supportMatchTypeList: [
          {
            label: "像",
            value: "like",
          },
        ],
      },
    ];
  },
  methods: {
    async getMsg() {
      const res = await userReq.userListCondition(this.page);
      this.userList = res.data.data
      this.page.total = res.data.total;
    },
    async changeCompanysList() {
      if(this.isKindType == 1) {
        this.tableData = this.userList
      }else {
        this.getPageList()
      }
      console.log('tableData:',this.tableData)
    },
    addLoginUser(row) {
      this.dialogFormVisible = true;
      this.dialogTitle = '创建登录账户'
      this.user.staffId = row.staffId
      this.user.username = ''
      this.user.pwd = ''
    },
    // 保存数据
    async saveUser() {
      if (this.user.pwd) {
        // console.log(this.user.pwd);
        this.user.pwd = Md5_utils.passwordMd5(this.user.pwd);
        const res = await userReq.saveLoginInfo(this.user);
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "创建登录账户成功",
          });
          this.user = {};
          this.dialogFormVisible = false;
        }
      } else {
        this.ElMessage({
          type: "error",
          message: "请输入密码",
        });
      }
    },
    async changeLoginStatus(scope) {
      console.log("触发开关", scope);
      const res1 = await userReq.getLoginInfoByStaffId(scope.staffId);
      if (res1.code === 200) {
        if (res1.data === null) {
          this.user.staffId = scope.staffId;
          this.user.nickName = scope.nickName;
          this.dialogFormVisible = true;
          scope.loginStatus = 2;
        } else {
          if (scope.loginStatus === 2) {
            this.$confirm("禁用后该用户无法登录，是否禁用", "警告", {
              confirmButtonText: "确认禁用",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(async () => {
                const res = await userReq.updateUser(scope);
                if (res.code && res.code === 200) {
                  this.ElMessage({
                    type: "success",
                    message: "禁用登录",
                  });
                }
              })
              .catch((error) => {
                scope.loginStatus = 1;
              });
          } else {
            const res = await userReq.updateUser(scope);
            if (res.code && res.code === 200) {
              if (scope.loginStatus === 1) {
                this.ElMessage({
                  type: "success",
                  message: "开启登录",
                });
              }
            }
          }
        }
      } else {
        this.ElMessage({
          type: "error",
          message: "修改登陆状态失败",
        });
      }
    },
    confirmChooseClick() {
      if (this.checkSelection()) {
        this.$emit("confirmChoose", this.selection[0]);
      }
    },
    async deleteCompanyDeptDtoById() {
      if (this.checkSelection()) {
        const res = await userReq.deleteUser(this.selection[0].staffId);
        if (res.code && res.code === 200) {
          this.ElMessage({
            type: "success",
            message: "删除成功",
          });
        }
      }
    },
    deleteById(row) {
      this.$confirm("删除进货单后将无法恢复，是否删除", "警告", {
        confirmButtonText: "确认删除",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await userReq.deleteUser(row.staffId);
        if (res.code && res.code === 200) {
          this.ElMessage({
            type: "success",
            message: "删除成功",
          });
          this.search();
        }
      });
    },
    async getStaffMsg(row) {
      // console.log(row)
      this.detailDialogVis = true;
      // const res11 = await userReq.getUserInfoByStaffId(row.staffId)
      this.companyDept = row || {};
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.search();
    },
    handleSizeChange(val) {
      this.page.pageNum = 1;
      this.page.pageSize = val;
      this.search();
    },
    getTree() {
      this.tableData = [];
      // 创建一个map，存储每个节点的所有子节点
      this.childrenMap = new Map();
      // 处理根节点
      let p = {};
      p.companyDeptName = "未分配部门";
      p.deptName = "未分配部门";
      p.staffId = 0;
      p.dept = 1
      p.children = [];
      this.childrenMap.set(p.staffId, p.children);
      this.tableData.push(p);
      // console.log('allCompanyDeptA:',this.allCompanyDeptA)
      for (let i = 0; i < this.allCompanyDeptA.length; i++) {
        let it = this.allCompanyDeptA[i];
        if (it.parentId == 0) {
          it.companyDeptName = it.deptName;
          it.staffId = it.companyDeptId;
          it.dept = 1
          it.children = [];
          // 把当前节点存储为其自身子节点的父节点
          this.childrenMap.set(it.companyDeptId, it.children);
          this.tableData.push(it);
        }
      }
      for (let i = 0; i < this.allCompanyDeptA.length; i++) {
        let it = this.allCompanyDeptA[i];
        if (it.parentId !== 0) {
          // 获取父节点的children
          let parentChildren = this.childrenMap.get(it.parentId);
          if (parentChildren) {
            it.companyDeptName = it.deptName;
            it.staffId = it.companyDeptId;
            it.dept = 1
            it.children = [];
            // 把当前节点存储为其自身子节点的父节点
            this.childrenMap.set(it.companyDeptId, it.children);
            parentChildren.push(it);
          }
        }
      }
      // console.log('tableData:',this.tableData)
    },
    async getPageList() {
      this.getTree();
      let data = this.userList;
      // 处理非根节点，将每个节点的子节点添加到父节点的children中
      for (let i = 0; i < data.length; i++) {
        let it = data[i];
        if (it.companyDeptId !== null && it.companyDeptId !== "") {
          // 获取父节点的children
          let parentChildren = this.childrenMap.get(it.companyDeptId);
          if (parentChildren) {
            // 把当前节点存储为其自身子节点的父节点
            parentChildren.push(it);
          }
        } else {
          // 获取父节点的children
          let parentChildren = this.childrenMap.get(0);
          it.companyDeptName = '未分配部门'
          // console.log(it)
          if (parentChildren) {
            // 把当前节点存储为其自身子节点的父节点
            parentChildren.push(it);
          }
        }
      }
      // console.log('tableData:',this.tableData)
    },
    async search() {
      await this.getMsg();
      this.changeCompanysList()
    },
    handleSelectionChange(val) {
      this.selection = val;
    },
    checkSelection() {
      if (!this.selection || this.selection.length === 0) {
        this.ElMessage({
          type: "warning",
          message: "请选择",
        });
        return false;
      }
      if (this.selection.length > 1) {
        this.ElMessage({
          type: "warning",
          message: "只能选择一个",
        });
        return false;
      }
      return true;
    },
    edit(row) {
      this.$router.push({
        path: "/acl/staff/edit-staff/" + row.staffId,
      });
    },
  },
};
</script>

<style scoped>
</style>
