import request from '@/utils/request'

export default  {

    getFile(filePath, fileName){
        return request({
            method:'GET',
            url:'buiBase/getFile',
            params:{
                filePath:filePath,
                fileName:fileName
            },
            responseType: 'blob'
        })
    },
    
    companyDeptListCondition(data) {
        return request({
            url: '/sysbase/company_dept/companyDeptListCondition',
            method: 'post',
            data: data
        })
    },
    getAllCompanyDept() {
        return request({
            url: '/sysbase/company_dept/getAllCompanyDept',
            method: 'get'
        })
    },
    selectAllCompanyDept() {
        return request({
            url: '/sysbase/company_dept/selectAllCompanyDept',
            method: 'get'
        })
    },
    getAllNationality() {
        return request({
            url: '/sysbase/company_dept/getAllNationality',
            method: 'get'
        })
    },
    saveCompanyDept(data) {
        return request({
            url: '/sysbase/company_dept/saveCompanyDept',
            method: 'post',
            data: data
        })
    },
    updateCompanyDept(data) {
        return request({
            url: '/sysbase/company_dept/updateCompanyDept',
            method: 'post',
            data: data
        })
    },
    getCompanyDeptDtoById(companyDeptId) {
        return request({
            url: '/sysbase/company_dept/getCompanyDeptDtoById',
            method: 'get',
            params: {
                companyDeptId: companyDeptId
            }
        })
    },
    deleteCompanyDeptDtoById(companyDeptId) {
        return request({
            url: '/sysbase/company_dept/deleteCompanyDeptDtoById',
            method: 'post',
            params: {
                companyDeptId: companyDeptId
            }
        })
    }
}
